<div class="tw-text-center tw-text-muted">
  <p bitTypography="body2" class="tw-mb-0">
    {{ "sendAccessTaglineProductDesc" | i18n }}
    {{ "sendAccessTaglineLearnMore" | i18n }}
    <a
      bitLink
      href="https://www.bitwarden.com/products/send?source=web-vault"
      target="_blank"
      rel="noreferrer"
      >Bitwarden Send</a
    >
    {{ "sendAccessTaglineOr" | i18n }}
    <a bitLink [routerLink]="registerRoute$ | async" target="_blank" rel="noreferrer">{{
      "sendAccessTaglineSignUp" | i18n
    }}</a>
    {{ "sendAccessTaglineTryToday" | i18n }}
  </p>
</div>
